import React from 'react'
import { useState } from 'react/cjs/react.production.min';
const Header = ({ handleToggleDarkMode}) => {
  return (
    <div className='header'>
        <h1>Notes</h1>
        <button onClick={() => handleToggleDarkMode(
            (previousDarkMode) => !previousDarkMode
            )
        } 
            className='saved'>Toggle Mode</button>
    </div>
  )
}

export default Header;


